<template>
    <div class="w-2/3 relative flex justify-start items-center">
        <p class="text-darkPurple font-bold text-lg">Trend</p>
        <div @mouseenter="onEnter" @mouseleave="onLeave" 
            class="absolute top left-52"
        >
            <Icon icon-name="icon-info" class="cursor-pointer" size="xs" />
            <ToolTip v-if="isTrendToolTip" style="width: 300px !important" left="-90px">   
                Trend allows you to compare your monthly salary costs. 
                Click the eye icon on Total Gross Pay or Total Net Pay to view the breakdown.
            </ToolTip>
        </div>
    </div>
</template>
<script>
export default {
    name: "TrendToolTip",
    components: {
        ToolTip: () => import("./ToolTip"),
    },
    props: {
        isTrendToolTip: Boolean
    },
    
    methods: {
        onEnter(){
            this.$emit("on-enter");
        },

        onLeave(){
            this.$emit("on-leave");
        },
    }
};
</script>

<style scoped>
.left-52{
    left: 52px !important
}
</style>
